import Cookies from 'js-cookie';
import { useState } from 'react';

import useImmutableCallback from './use-immutable-callback.js';

export default function useCookie(key, initialValue, options) {
	const [value, setValue] = useState(() => {
		let cookie = Cookies.getJSON(key);
		if (cookie != undefined) {
			return cookie;
		}

		if (initialValue) {
			Cookies.set(key, initialValue, options);
		}

		return initialValue;
	});

	let setCookie = useImmutableCallback(function (value, options) {
		setValue(value);
		Cookies.set(key, value, options);
	});

	return [value, setCookie];
}
