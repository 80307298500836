import usePageContext from './use-page-context.js';

let translations = {};
translations.faq = require(`../translations/faq.js`).default;
translations.careers = require(`../translations/careers.js`).default;
translations.footer = require(`../translations/footer.js`).default;
translations.contact = require(`../translations/contact.js`).default;
translations.references = require(`../translations/references`).default;
translations.testimonials = require(`../translations/testimonials.js`).default;
translations['first-meeting'] = require(`../translations/first-meeting.js`).default;

export default function useTranslate(filename) {
	let { language } = usePageContext();

	const t = (value) => {
		let file = translations[filename];
		if (file == undefined) {
			throw new Error(`File (${filename}) not found in translations folder`);
		}

		if (file[value] == undefined) {
			throw new Error(`Value ${value} not found in translationsfile ${filename}`);
		}

		return file[value][language] || value;
	};

	return t;
}
