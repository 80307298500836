import React from 'react';

import { graphql, Link, useStaticQuery } from 'gatsby';

import Button from './button.jsx';
import LogoSmall from '../icons/logo-small.jsx';
import useTranslate from '../hooks/use-translate.js';
import usePageContext from '../hooks/use-page-context.js';

export default function Footer() {
	let data = useStaticQuery(query);
	let t = useTranslate('footer');
	let { language } = usePageContext();

	let { sanityCompanyInfo } = data;
	let { information, socials, offices } = sanityCompanyInfo;

	let headquarter = offices.find((o) => o.headquarter === true);

	let renderInformation = [];
	for (let info of information || []) {
		renderInformation.push(
			<a
				key={info._key}
				href={info.type === 'link' ? info.url : `${info.type}${info.value}`}
				target="_blank"
				rel="noreferrer"
				className="font-light transition-transform transform md:hover:translate-x-2 hover:text-primary focus:translate-x-2 focus:text-primary focus:outline-none"
			>
				{info.value}
			</a>,
		);
	}

	let renderSocials = [];
	for (let social of socials || []) {
		renderSocials.push(
			<a
				key={social._key}
				href={social.url}
				target="_blank"
				rel="noreferrer"
				className="font-light transition-transform transform md:hover:-translate-x-2 hover:text-primary focus:-translate-x-2 focus:text-primary focus:outline-none"
			>
				{social.name}
			</a>,
		);
	}

	let renderHeadquarterContactInfo;
	if (headquarter) {
		renderHeadquarterContactInfo = (
			<a
				href={headquarter.url}
				target="_blank"
				rel="noreferrer"
				className="flex flex-col mt-4 font-light transition-transform transform md:hover:translate-x-2 hover:text-primary focus:translate-x-2 focus:text-primary focus:outline-none"
			>
				<span>{headquarter.address_1}</span>
				<span>{headquarter.address_2}</span>
			</a>
		);
	}

	return (
		<>
			<footer className="pt-12 md:pt-20 xl:pt-32">
				<div className="grid gap-8 md:grid-cols-3 items-x-center">
					<div className="text-center md:self-x-start md:text-left">
						<h3 className="text-2xl">{t('information')}</h3>
						<div className="flex flex-col mt-4 space-y-1">{renderInformation}</div>
						{renderHeadquarterContactInfo}
					</div>
					<div className="flex flex-col items-center justify-center space-y-8 md:self-x-center">
						<div className="md:-mt-16">
							<LogoSmall />
						</div>
						<h3 className="text-2xl text-center">{t('contact-title')}</h3>
						<span className="self-center">
							<Button as="link" to={`/${language}/contact`} ariaLabel="Contact Us">
								{t('contact-cta-text')}
							</Button>
						</span>
					</div>
					<div className="md:self-x-end">
						<h3 className="text-2xl text-right">{t('socials')}</h3>
						<div className="flex flex-col items-center mt-4 space-y-1 text-center md:items-end md:text-right">
							{renderSocials}
						</div>
					</div>
				</div>

				<div className="flex flex-col justify-between py-4 mt-20 text-xs border-t-2 sm:items-center sm:flex-row border-primary text-primary">
					<Link
						to={`/${language}/privacy-cookies-policy`}
						className="focus:outline-none focus:underline hover:underline"
					>
						Privacy & Cookies policy
					</Link>
					<div className="flex justify-between mt-1 sm:mt-0 sm:justify-start">
						<p>
							Part of{' '}
							<a
								href="https://www.raccoons.be"
								target="_blank"
								rel="noreferrer"
								className="focus:outline-none focus:underline hover:underline"
							>
								Raccoons
							</a>
						</p>
						<p className="ml-12 select-none">Copyrights Brainjar {new Date(Date.now()).getFullYear()}</p>
					</div>
				</div>
			</footer>
		</>
	);
}

const query = graphql`
	query FooterQuery {
		sanityCompanyInfo {
			socials {
				_key
				name
				url
			}
			information {
				_key
				type
				value
				url
			}
			offices {
				_key
				headquarter
				address_1
				address_2
				url
			}
		}
	}
`;
