import React from 'react';

import SEO from './seo.jsx';
import Header from './header.jsx';
import Footer from './footer.jsx';
import CookieModal from './cookie-modal.jsx';

export default function Layout(props) {
	let { seo } = props;
	let { description, image } = seo ?? {};

	return (
		<>
			<SEO description={description} image={image} />

			<div className="min-h-screen grid grid-rows-[max-content,1fr] text-white">
				<div className="sticky top-0 z-50 shadow bg-secondary">
					<Header />
				</div>
				<div className="w-full grid grid-rows-[1fr,max-content] px-6 self-x-center sm:px-16 md:px-24 lg:px-40 max-w-screen-4xl">
					<main>{props.children}</main>
					<Footer />
				</div>
			</div>

			<CookieModal />
		</>
	);
}
