export default {
	'testimonials-title': {
		en: 'Satisfied Customers',
		nl: 'Tevreden Klanten',
	},
	'information': {
		en: 'Information',
		nl: 'Informatie',
	},
	'contact-title': {
		en: 'Contact Us',
		nl: 'Contacteer Ons',
	},
	'contact-cta-text': {
		en: 'Here',
		nl: 'Hier',
	},
	'socials': {
		en: 'Socials',
		nl: 'Sociale Media',
	},
};
