import React, { useEffect, useRef, useState } from 'react';

import { Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import trapFocus from '../utils/trap-focus.js';
import useCookie from '../hooks/use-cookie.js';
import usePageContext from '../hooks/use-page-context.js';

export default function CookieModal() {
	let { language } = usePageContext();
	let location = useLocation();
	let modalRef = useRef();
	let [showModal, setShowModal] = useState(false);
	let [cookieBannerSubmitted, setCookieBannerSubmitted] = useCookie('cookie-banner-submitted', false);
	let [, setDisableGoogleAnalytics] = useCookie(`ga-disable-G-ETKRV0NE6Y`, true, { expires: 30 });

	useEffect(() => {
		if (modalRef.current) {
			modalRef.current.focus();
		}
	}, []);

	useEffect(() => {
		setShowModal(!cookieBannerSubmitted);
	}, [cookieBannerSubmitted]);

	function handleFunctionalOnlyClick() {
		setCookieBannerSubmitted(true, { expires: 30 });
	}

	function handleAcceptAllClick() {
		setCookieBannerSubmitted(true, { expires: 30 });
		setDisableGoogleAnalytics(false, { expires: 30 });
		navigate(location.pathname, { replace: true });
	}

	if (!showModal) return null;

	return (
		<div
			ref={modalRef}
			tabIndex={-1}
			aria-labelledby="CookieBanner"
			role="dialog"
			aria-modal="true"
			onKeyDown={trapFocus}
			className="fixed focus:outline-none left-6 md:left-auto right-6 z-[100] inline-block px-12 py-6 text-left shadow-2xl bottom-6 md:bottom-12 rounded-tr-3xl rounded-l-3xl bg-tertiary md:right-12"
		>
			<h1 className="inline-block py-2 text-3xl border-b-2 text-primary border-primary">
				Experience more through cookies
			</h1>
			<p className="py-2 mt-2 text-lg text-white">
				We use cookies or similar technologies as specified in our{' '}
				<Link
					to={`/${language}/privacy-cookies-policy#cookies`}
					className="focus:underline focus:outline-none text-primary"
				>
					cookie policy
				</Link>
			</p>
			<div className="flex flex-col items-center justify-between mt-2 md:flex-row">
				<button
					onClick={handleFunctionalOnlyClick}
					className="w-full px-3 py-1 text-lg border-2 rounded-full md:w-auto border-primary text-primary focus:outline-none focus:bg-primary focus:text-secondary"
				>
					Only functional cookies
				</button>
				<button
					onClick={handleAcceptAllClick}
					className="w-full px-3 py-1 mt-4 text-lg border-2 rounded-full md:mt-0 md:w-auto text-tertiary bg-primary border-primary focus:ring-2 focus:outline-none focus:ring-primary focus:ring-offset-2 focus:ring-offset-tertiary"
				>
					Accept All
				</button>
			</div>
		</div>
	);
}
