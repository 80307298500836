import React from 'react';

import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import usePageContext from '../hooks/use-page-context.js';

export default function SEO(props) {
	let { description: pageDescription, image: pageImage } = props;
	let { title: pageTitle, language, locale, originalPath } = usePageContext();

	let data = useStaticQuery(query);
	let { sanitySiteInfo } = data;
	let { domain, title: siteName, description: defaultDescription, keywords, image: defaultImage } = sanitySiteInfo;
	let { twitterHandle, facebookHandle } = sanitySiteInfo;

	keywords = keywords.split(',').map((keyword) => keyword.trim());

	let url = `${domain}/${language}${originalPath}`;
	let image = pageImage ?? defaultImage;
	let title = pageTitle ? `${siteName} | ${pageTitle}` : siteName;
	let description = pageDescription ?? defaultDescription;

	return (
		<Helmet defer={true}>
			<html lang={language} />
			<title>{title}</title>

			<meta name="robots" content="all" />
			<meta name="keywords" content={keywords} />
			<meta name="description" content={description} />

			<meta property="og:type" content="website" />
			<meta property="og:site_name" content={siteName} />
			<meta property="og:title" content={title} />
			<meta property="og:url" content={url} rel="canonical" />
			<meta property="og:locale" content={locale} />
			<meta property="og:image" content={image?.asset?.url} />
			<meta property="og:image:secure_url" content={image?.asset?.url} />

			<link href={url} rel="canonical" />
			<link href={domain} rel="home" />

			<meta property="fb:profile_id" content={facebookHandle} />

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content={twitterHandle} />
			<meta name="twitter:creator" content={twitterHandle} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={image?.asset?.url} />
		</Helmet>
	);
}

const query = graphql`
	query SEO {
		sanitySiteInfo {
			domain
			title
			description
			keywords
			twitterHandle
			facebookHandle
			image {
				asset {
					url
				}
			}
		}
	}
`;
