import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'gatsby';
import classNames from 'classnames';
import { useLocation } from '@reach/router';

import Logo from '../icons/logo.jsx';
import languages from '../../../shared/languages.js';
import usePageContext from '../hooks/use-page-context.js';
import { useScreenSizeIndex } from '../hooks/use-screen-size.js';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';

export default function Header() {
	let [menuOpen, setMenuOpen] = useState(false);
	let { language, originalPath, multilang } = usePageContext();
	let screenSizeIndex = useScreenSizeIndex();
	let location = useLocation();
	let search = location.search;

	useEffect(() => {
		if (!menuOpen) return;

		let currentClassName = document.body.className;
		let currentBodyPaddingRight = document.body.style.paddingRight;
		document.body.classList.add('overflow-hidden');

		return () => {
			document.body.className = currentClassName;
			document.body.style.paddingRight = currentBodyPaddingRight;
		};
	}, [menuOpen]);

	useEffect(() => {
		if (screenSizeIndex >= 3 && menuOpen) {
			setMenuOpen(false);
		}
	}, [menuOpen, screenSizeIndex]);

	let renderLanguages = [];
	if (multilang) {
		for (let lang of languages) {
			let { code } = lang;

			let className = classNames('focus:outline-none focus:underline focus:text-primary', {
				'text-primary': language === `${code}`,
				'text-tertiary': language !== code,
			});

			renderLanguages.push(
				<Link
					key={code}
					to={`/${code}${originalPath.endsWith('/') ? originalPath : `${originalPath}/`}${search}`}
					className={className}
				>
					{code.toUpperCase()}
				</Link>,
			);

			if (renderLanguages.length - renderLanguages.length / 2 < languages.length - 1) {
				renderLanguages.push(
					<span key={code + '/'} className="text-tertiary">
						/
					</span>,
				);
			}
		}
	}

	return (
		<header className="relative flex items-center justify-between w-full px-6 py-4 mx-auto space-x-6 text-lg lg:py-6 sm:px-16 md:px-24 lg:px-40 max-w-screen-4xl">
			<Link
				to={`/${language}/`}
				aria-label="Home"
				className="w-40 rounded md:w-56 focus:outline-none focus-visible:ring focus-visible:ring-tertiary focus-visible:ring-opacity-50 ring-offset-4 focus:ring-offset-secondary"
			>
				<Logo />
			</Link>

			{/* Desktop navigation */}
			<nav className="hidden xl:block">
				<div
					hidden={!multilang}
					className="absolute space-x-2 text-base right-6 sm:right-12 md:right-20 lg:right-32 top-2"
				>
					{renderLanguages}
				</div>

				<ul className="relative flex items-center text-lg font-light text-gray-200 lg:space-x-16 2xl:space-x-20 2xl:text-xl">
					<DropdownMenuItem
						title="About"
						items={[
							{
								title: 'Company',
								to: '/company',
								multilang: true,
								description: 'Discover our approach to artificial intelligence.',
							},
							{
								title: 'Team',
								to: '/team',
								multilang: true,
								description: 'Meet our team, filled with human intelligence.',
							},
							{
								title: 'Careers',
								to: '/careers',
								multilang: true,
								description: 'Are you our next set of brains? Join us!',
							},
						]}
					/>
					<DropdownMenuItem
						title="Services"
						items={[
							{
								title: 'Solutions',
								to: '/solutions',
								multilang: true,
								description: 'Our end-to-end AI solutions laid out for you.',
							},
							{
								title: 'Industries',
								to: '/industries',
								multilang: true,
								description: 'Discover our solutions for every industry.',
							},
							{
								title: 'Benefits',
								to: '/benefits',
								multilang: true,
								description: 'Why you should be using AI for your business.',
							},
						]}
					/>
					<DropdownMenuItem
						title="Insights"
						items={[
							{
								title: 'Blogs',
								to: '/blogs',
								multilang: false,
								description: 'Discover our insights into artificial intelligence.',
							},
							{
								title: 'FAQ',
								to: '/faq',
								multilang: true,
								description: 'Your questions about artificial intelligence, answered easily.',
							},
						]}
					/>
					<li>
						<Link to="/en/references" className="font-light focus:outline-none focus-visible:underline">
							References
						</Link>
					</li>
					<li>
						<Link
							to="/en/contact"
							className="px-3 py-1 font-medium rounded-lg bg-primary text-secondary focus:outline-none focus-visible:ring focus:ring-primary focus:ring-opacity-30"
						>
							Contact
						</Link>
					</li>
				</ul>
			</nav>

			{/* Mobile navigation */}
			<nav className="contents xl:hidden">
				<button
					aria-label="Open menu"
					onClick={() => setMenuOpen(true)}
					className="xl:hidden focus:outline-none hover:text-primary focus:ring focus:ring-primary"
				>
					<svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
					</svg>
				</button>

				<div
					className="fixed inset-0 z-10 px-6 py-4 lg:py-6 sm:px-12 md:px-20 lg:px-32 bg-secondary"
					hidden={!menuOpen}
				>
					<div className="flex items-center justify-between">
						<Link
							to={`/${language}/`}
							aria-label="Home"
							className="w-40 md:w-56 focus:outline-none focus:ring focus:ring-primary ring-offset-4 focus:ring-offset-secondary"
						>
							<Logo />
						</Link>

						<button
							aria-label="Close menu"
							onClick={() => setMenuOpen(false)}
							className="xl:hidden focus:outline-none hover:text-primary focus:ring focus:ring-primary"
						>
							<svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					</div>

					<ul className="h-full py-12 space-y-4 text-2xl font-light text-gray-200">
						<MobileDropdownMenuItem
							title="About"
							items={[
								{
									title: 'Company',
									to: '/company',
									multilang: true,
									description: 'Discover our approach to artificial intelligence.',
								},
								{
									title: 'Team',
									to: '/team',
									multilang: true,
									description: 'Meet our team, filled with human intelligence.',
								},
								{
									title: 'Careers',
									to: '/careers',
									multilang: true,
									description: 'Are you our next set of brains? Join us!',
								},
							]}
						/>
						<MobileDropdownMenuItem
							title="Services"
							items={[
								{
									title: 'Solutions',
									to: '/solutions',
									multilang: true,
									description: 'Our end-to-end AI solutions laid out for you.',
								},
								{
									title: 'Industries',
									to: '/industries',
									multilang: true,
									description: 'Discover our solutions for every industry.',
								},
								{
									title: 'Benefits',
									to: '/benefits',
									multilang: true,
									description: 'Why you should be using AI for your business.',
								},
							]}
						/>
						<MobileDropdownMenuItem
							title="Insights"
							items={[
								{
									title: 'Blogs',
									to: '/blogs',
									multilang: false,
									description: 'Discover our insights into artificial intelligence.',
								},
								{
									title: 'FAQ',
									to: '/faq',
									multilang: true,
									description: 'Your questions about artificial intelligence, answered easily.',
								},
							]}
						/>
						<li>
							<Link to="/en/references" className="font-light focus:outline-none focus-visible:underline">
								References
							</Link>
						</li>
						<li className="flex items-center justify-center">
							<Link
								to="/en/contact"
								className="px-3 py-1 font-medium rounded-lg bg-primary text-secondary focus:outline-none focus-visible:ring focus:ring-primary focus:ring-opacity-30"
							>
								Contact
							</Link>
						</li>
					</ul>

					<div className="fixed flex justify-center w-full -mx-6 space-x-2 text-base sm:-mx-12 md:-mx-20 lg:-mx-32 bottom-4">
						{renderLanguages}
					</div>
				</div>
			</nav>
		</header>
	);
}

function DropdownMenuItem(props) {
	let { title, items } = props;
	let { language } = usePageContext();
	let dropdownRef = useRef();

	let [open, setOpen] = useState(false);

	useEffect(() => {
		dropdownRef.current.focus();
	}, []);

	useEffect(() => {
		if (!open) return;

		function keyCloseListener(event) {
			if (event.key === 'Escape') {
				setOpen(false);
			}
		}

		function clickCloseListener() {
			setOpen(false);
		}

		window.addEventListener('keyup', keyCloseListener);
		window.addEventListener('click', clickCloseListener);

		return () => {
			window.removeEventListener('keyup', keyCloseListener);
			window.removeEventListener('click', clickCloseListener);
		};
	}, [open]);

	function handleClick() {
		setOpen(!open);
	}

	let buttonClassName = classNames('relative font-light flex items-center focus:outline-none focus-visible:underline', {
		'text-primary': open,
	});

	let chevronClassName = classNames('w-6 h-6 ml-2 transform transition-transform duration-500', {
		'rotate-0': !open,
		'-rotate-180': open,
	});

	let renderDropdown;
	let renderItems = [];
	for (let item of items) {
		let to;
		if (item.multilang) {
			to = `/${language}${item.to}`;
		} else {
			to = item.to;
		}

		renderItems.push(
			<li key={item.title} className="text-white group">
				<Link to={to} className="focus:outline-none">
					<div className="flex items-center font-medium">
						<ChevronRightIcon className="flex-shrink-0 w-5 h-5 transition-transform transform group-focus-within:translate-x-1 group-hover:translate-x-1" />
						<span className="ml-1 text-base 2xl:text-lg">{item.title}</span>
					</div>
					<div className="pl-6 mt-1 text-xs 2xl:text-base">{item.description}</div>
				</Link>
			</li>,
		);
	}

	let renderDropdownClassName = classNames(
		'absolute z-50 shadow-lg left-0 right-0 flex p-4 py-4 mt-2 space-x-2 rounded bg-tertiary space-between transform transition-transform',
		{ 'h-0 w-0 invisible': !open, 'h-auto': open },
	);

	renderDropdown = <ul className={renderDropdownClassName}>{renderItems}</ul>;

	return (
		<li ref={dropdownRef}>
			<button onClick={handleClick} className={buttonClassName}>
				{title} <ChevronDownIcon className={chevronClassName} />
				{open && (
					<div className="absolute w-4 h-4 transform rotate-45 -bottom-5 left-[calc(50%-1rem)] bg-tertiary"></div>
				)}
			</button>
			{renderDropdown}
		</li>
	);
}

function MobileDropdownMenuItem(props) {
	let { title, items } = props;
	let { language } = usePageContext();
	let dropdownRef = useRef();

	let [open, setOpen] = useState(false);

	useEffect(() => {
		dropdownRef.current.focus();
	}, []);

	useEffect(() => {
		if (!open) return;

		function keyCloseListener(event) {
			if (event.key === 'Escape') {
				setOpen(false);
			}
		}

		function clickCloseListener() {
			setOpen(false);
		}

		window.addEventListener('keyup', keyCloseListener);
		window.addEventListener('click', clickCloseListener);

		return () => {
			window.removeEventListener('keyup', keyCloseListener);
			window.removeEventListener('click', clickCloseListener);
		};
	}, [open]);

	function handleClick() {
		setOpen(!open);
	}

	let buttonClassName = classNames(
		'font-light flex justify-between w-full items-center focus:outline-none focus-visible:underline',
		{
			'text-primary': open,
		},
	);

	let chevronClassName = classNames('w-6 h-6 ml-2 transform transition-transform duration-500', {
		'rotate-0': !open,
		'-rotate-180': open,
	});

	let renderDropdown;
	let renderItems = [];
	for (let item of items) {
		let to;
		if (item.multilang) {
			to = `/${language}${item.to}`;
		} else {
			to = item.to;
		}

		renderItems.push(
			<li key={item.title} className="text-white group">
				<Link to={to} className="focus:outline-none">
					<div className="flex items-center font-medium">
						<ChevronRightIcon className="flex-shrink-0 w-5 h-5 transition-transform transform group-focus-within:translate-x-1 group-hover:translate-x-1" />
						<span className="ml-1 text-base 2xl:text-lg">{item.title}</span>
					</div>
					<div className="pl-6 mt-1 text-xs 2xl:text-base">{item.description}</div>
				</Link>
			</li>,
		);
	}

	let renderDropdownClassName = classNames('mt-2 ml-4 space-y-2', { 'h-0 w-0 invisible': !open, 'h-auto': open });

	renderDropdown = <ul className={renderDropdownClassName}>{renderItems}</ul>;

	return (
		<li ref={dropdownRef} className="flex flex-col">
			<button onClick={handleClick} className={buttonClassName}>
				{title} <ChevronDownIcon className={chevronClassName} />
			</button>
			{renderDropdown}
		</li>
	);
}
