export default {
	'send': {
		en: 'Send',
		nl: 'Verstuur',
	},
	'success': {
		en: 'Thank you for getting in touch!',
		nl: 'Bedankt om contact op te nemen!',
	},
	'error': {
		en: 'Woops! Something went wrong. Please try again later.',
		nl: 'Woops! Er ging iets fout. Probeer het later nog eens.',
	},
	'contact-information': {
		en: 'Contact Information',
		nl: 'Contact gegevens',
	},
};
