import React from 'react';

export default function Logo() {
	return (
		<svg viewBox="0 0 296.891 75.948">
			<g transform="translate(-70.629 -55.297)">
				<g className="text-primary">
					<path
						d="M104.944,93.012a1.26,1.26,0,0,1-.77-.262l-1.131,5.586a1.261,1.261,0,0,1,1.087.22l1.131-5.586A1.275,1.275,0,0,1,104.944,93.012Z"
						fill="currentColor"
					/>
					<path
						d="M96.739,107.148l2.453,5.344a1.271,1.271,0,0,1,1-.484l-2.443-5.322a1.269,1.269,0,0,1-.982.463Z"
						fill="currentColor"
					/>
					<path
						d="M95.493,105.9l-4.582,2.338a1.269,1.269,0,0,1,.5.988L96,106.89A1.271,1.271,0,0,1,95.493,105.9Z"
						fill="currentColor"
					/>
					<path
						d="M97.207,94.541a1.275,1.275,0,0,1-.65.9l5.548,3.92a1.273,1.273,0,0,1,.639-.906Z"
						fill="currentColor"
					/>
					<path
						d="M89.711,101.131,95.5,95.507a1.276,1.276,0,0,1-.762-.806l-5.8,5.635A1.274,1.274,0,0,1,89.711,101.131Z"
						fill="currentColor"
					/>
					<path
						d="M87.459,102.259l-2.745,4.9a1.268,1.268,0,0,1,.945.582l2.768-4.938A1.268,1.268,0,0,1,87.459,102.259Z"
						fill="currentColor"
					/>
					<path
						d="M111.971,98.485l-3.179,5.327a1.274,1.274,0,0,1,.9.659l3.233-5.418A1.27,1.27,0,0,1,111.971,98.485Z"
						fill="currentColor"
					/>
					<path
						d="M95.955,93.045a1.27,1.27,0,0,1,.911.384l.765-6.975a1.28,1.28,0,0,1-.4.066,1.26,1.26,0,0,1-.7-.209l-.74,6.746A1.267,1.267,0,0,1,95.955,93.045Z"
						fill="currentColor"
					/>
					<path
						d="M109.5,87.793c-.037,0-.073,0-.109-.006l2.614,8.538-5.813-4.345a1.273,1.273,0,0,1-.665.888l6.255,4.676a1.266,1.266,0,0,1,1.445-1.015l-2.564-8.372,6.089,5.134a1.276,1.276,0,0,1,.715-.849l-6.72-5.666A1.274,1.274,0,0,1,109.5,87.793Z"
						fill="currentColor"
					/>
					<path
						d="M117.828,106.061l-3.784-7.5a1.271,1.271,0,0,1-.99.5l3.84,7.615A1.272,1.272,0,0,1,117.828,106.061Z"
						fill="currentColor"
					/>
					<path
						d="M104.558,99.992a1.273,1.273,0,0,1-.788.78l3.663,3.708a1.269,1.269,0,0,1,.9-.667Z"
						fill="currentColor"
					/>
					<path
						d="M119.074,94.264a1.274,1.274,0,0,1-.931.6l2.321,3.587-6.155-1.019,3.16-2.668a1.277,1.277,0,0,1-.715-.848l-3.2,2.7a1.271,1.271,0,0,1,.522,1.9l6.318,1.046a1.259,1.259,0,0,1-.053-.36,1.273,1.273,0,0,1,1.117-1.263Z"
						fill="currentColor"
					/>
					<path
						d="M121.611,100.479a1.267,1.267,0,0,1-.962-.442l-2.6,6.017a1.269,1.269,0,0,1,.975.539l2.639-6.117C121.648,100.477,121.63,100.479,121.611,100.479Z"
						fill="currentColor"
					/>
					<path
						d="M84.454,107.156l-3.01-6.14a1.265,1.265,0,0,1-.984.513l3.049,6.219A1.273,1.273,0,0,1,84.454,107.156Z"
						fill="currentColor"
					/>
					<path d="M88.942,109.672a1.263,1.263,0,0,1,.13-1.1l-3.234-.38a1.26,1.26,0,0,1-.3,1.08Z" fill="currentColor" />
					<path
						d="M98.94,113.514l-4.808,2.665a1.269,1.269,0,0,1,.588.942l4.888-2.709A1.272,1.272,0,0,1,98.94,113.514Z"
						fill="currentColor"
					/>
					<path
						d="M96.9,93.464a1.271,1.271,0,0,1,.33.854,1.226,1.226,0,0,1-.019.211L104,92.587a1.268,1.268,0,0,1-.326-.848,1.311,1.311,0,0,1,.02-.219Z"
						fill="currentColor"
					/>
					<path
						d="M102.148,99.959l-4.939,4.725a1.276,1.276,0,0,1,.767.8l4.939-4.725A1.274,1.274,0,0,1,102.148,99.959Z"
						fill="currentColor"
					/>
					<path
						d="M116.714,107.323a1.261,1.261,0,0,1,.135-.568l-7.015-1.735c0,.014,0,.029,0,.044a1.273,1.273,0,0,1-.466.984l6.613,1.636-3.407,1.122-4.537,3.25.926-5.785a1.268,1.268,0,0,1-1.546-.651l-6.663,6.522a1.268,1.268,0,0,1,.507,1.821l4.868-.1a1.259,1.259,0,0,1-.024-1.109l-4.485.1,6.127-6-.833,5.209a1.269,1.269,0,0,1,1.6,1.02l4.566-3.27,4.24-1.4A1.272,1.272,0,0,1,116.714,107.323Z"
						fill="currentColor"
					/>
					<path
						d="M93.425,115.983l-2.342-5.87a1.268,1.268,0,0,1-.939.415c-.031,0-.061,0-.091,0l2.382,5.971A1.268,1.268,0,0,1,93.425,115.983Z"
						fill="currentColor"
					/>
					<path
						d="M106.12,91.251l3.1-3.49a1.274,1.274,0,0,1-.869-.692l-3.063,3.446A1.274,1.274,0,0,1,106.12,91.251Z"
						fill="currentColor"
					/>
					<path
						d="M96.01,104.853l-6.236-3.28a1.268,1.268,0,0,1-.516.981l6.236,3.28A1.268,1.268,0,0,1,96.01,104.853Z"
						fill="currentColor"
					/>
					<path
						d="M81.468,99.531l4.11-6.85c1.113,4.436,1.71,6.724,2.055,7.922a1.253,1.253,0,0,1,1.057-.33c-.482-1.744-1.407-5.361-2.1-8.1a1.271,1.271,0,0,1-1.8-.342L80.5,98.989A1.27,1.27,0,0,1,81.468,99.531Z"
						fill="currentColor"
					/>
					<path
						d="M86.607,90.1a1.271,1.271,0,0,1,.184,1.9l7.9,2.43a1.035,1.035,0,0,1-.006-.112,1.268,1.268,0,0,1,.4-.927l-7.791-2.4,9.036-4.846a1.248,1.248,0,0,1-.362-1.064Z"
						fill="currentColor"
					/>
					<path
						d="M98.33,84.611a1.269,1.269,0,0,1-.449,1.727l5.835,5.069a1.273,1.273,0,0,1,.727-.838L98.956,85.8l9.339,1.121a1.262,1.262,0,0,1,.141-1.1Z"
						fill="currentColor"
					/>
				</g>
				<path
					d="M290.221,116.053H284.7V102.241a8.3,8.3,0,0,0-8.287-8.287h-8.288v22.1H262.6V88.429h13.812a13.828,13.828,0,0,1,13.812,13.812Z"
					fill="currentColor"
					className="text-white"
				/>
				<path
					d="M226.685,88.429a13.812,13.812,0,1,0,8.288,24.855v2.769H240.5V88.429Zm0,22.1a8.287,8.287,0,1,1,0-16.574h8.288v8.287A8.3,8.3,0,0,1,226.685,110.528Z"
					fill="currentColor"
					className="text-primary"
				/>
				<path
					d="M326.919,88.429a13.812,13.812,0,1,0,8.287,24.855v2.769h5.525V88.429Zm0,22.1a8.287,8.287,0,1,1,0-16.574h8.287v8.287A8.3,8.3,0,0,1,326.919,110.528Z"
					fill="currentColor"
					className="text-white"
				/>
				<rect width="5.525" height="27.624" transform="translate(248.785 88.429)" fill="#e1fb4a" />
				<path
					d="M292.983,127.1v-5.525a8.3,8.3,0,0,0,8.287-8.287V88.429H306.8v24.862A13.828,13.828,0,0,1,292.983,127.1Z"
					fill="currentColor"
					className="text-white"
				/>
				<rect
					width="5.525"
					height="5.525"
					transform="translate(301.27 77.379)"
					fill="currentColor"
					className="text-white"
				/>
				<rect
					width="5.525"
					height="5.525"
					transform="translate(248.785 77.379)"
					fill="currentColor"
					className="text-primary"
				/>
				<path
					d="M210.173,89.609A13.8,13.8,0,0,0,196.3,91.2V88.429h-5.525v27.624H196.3V102.241a8.287,8.287,0,0,1,13.768-6.216l.106-.121Z"
					fill="currentColor"
					className="text-white"
				/>
				<path
					d="M362.043,88.429a13.741,13.741,0,0,0-8.287,2.769V88.429h-5.525v27.624h5.525V102.241a8.288,8.288,0,0,1,13.764-6.22V89.56A13.819,13.819,0,0,0,362.043,88.429Z"
					fill="currentColor"
					className="text-white"
				/>
				<path
					d="M171.1,87.768a14.074,14.074,0,0,0-8.62,2.939V77.391h-5.523v24.856h0A14.143,14.143,0,1,0,171.1,87.768Zm0,22.764a8.62,8.62,0,1,1,8.62-8.62A8.63,8.63,0,0,1,171.1,110.532Z"
					fill="currentColor"
					className="text-white"
				/>
				<path
					d="M101.009,131.245v-5.523a24.855,24.855,0,0,0,24.856-24.856V77.391h5.523v23.475a30.38,30.38,0,0,1-30.379,30.379Z"
					fill="currentColor"
					className="text-white"
				/>
				<path
					d="M97.324,125.452a24.648,24.648,0,0,1-13.446-6.576q-.225-.214-.445-.434c-.147-.147-.291-.295-.434-.445a24.656,24.656,0,0,1-6.577-13.446,25.294,25.294,0,0,1-.269-3.685,24.856,24.856,0,1,1,49.712,0V83.386a30.764,30.764,0,0,0-3.375-4,30.379,30.379,0,0,0-42.963,0,30.756,30.756,0,0,0-3.374,4V55.3H70.629v45.569a30.38,30.38,0,0,0,30.38,30.379v-5.523A25.155,25.155,0,0,1,97.324,125.452Z"
					fill="currentColor"
					className="text-tertiary"
				/>
			</g>
		</svg>
	);
}
