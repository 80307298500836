export default {
	title: {
		en: 'Satisfied clients',
		nl: 'Tevreden Klanten',
	},
	subtitle: {
		en: 'Discover our cases',
		nl: 'Ontdenk onze cases',
	},
};
