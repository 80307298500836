export default {
	'title': {
		en: 'Want to discuss if AI is something for your business?',
		nl: 'TODO',
	},
	'subtitle': {
		en: 'Plan a meeting',
		nl: 'Klaar met ontdekken en klaar om het moederschip te verlaten?',
	},
	'primary-button-title': {
		en: 'Without obligation',
		nl: 'Vrijblijvend',
	},
	'primary-button-subtitle': {
		en: 'first intake interview',
		nl: 'eerste intake gesprek',
	},
	'secondary-button-title': {
		en: 'AI workshop',
		nl: 'AI workshop',
	},
	'secondary-button-subtitle': {
		en: '(4h - 475 euro)',
		nl: '(4u - 475 euro)',
	},
};
