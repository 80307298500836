export default {
	title: {
		en: 'Open positions',
		nl: 'Open posities',
	},
	subtitle: {
		en: 'Are you our next set of brains?',
		nl: 'Ben jij ons volgende stel hersenen?',
	},
};
