import { useContext } from 'react';

import ScreenSizeContext from '../context/screen-size.js';

export default function useScreenSize() {
	return useContext(ScreenSizeContext).size;
}

export function useScreenSizeIndex() {
	return useContext(ScreenSizeContext).index;
}
