export default {
	'answer-not-found': {
		en: "Didn't find your answer?",
		nl: 'Jouw antwoord niet gevonden?',
	},
	'answer-not-found-cta-text': {
		en: 'Contact Us',
		nl: 'Contacteer Ons',
	},
};
