import React from 'react';

export default function LogoSmall() {
	return (
		<svg width="95.485" height="119.355" viewBox="0 0 95.485 119.355">
			<g transform="translate(-70.629 -55.297)">
				<g transform="translate(86.079 101.365)">
					<path
						d="M106.03,93.162a1.98,1.98,0,0,1-1.21-.412l-1.777,8.779a1.981,1.981,0,0,1,1.708.346l1.777-8.779A2,2,0,0,1,106.03,93.162Z"
						transform="translate(-67.553 -79.959)"
						fill="#e1fb4a"
					/>
					<path
						d="M96.739,107.412l3.855,8.4a2,2,0,0,1,1.568-.761l-3.839-8.364a2,2,0,0,1-1.543.728Z"
						transform="translate(-71.156 -71.994)"
						fill="#e1fb4a"
					/>
					<path
						d="M98.112,105.9l-7.2,3.674a2,2,0,0,1,.792,1.553l7.2-3.676A2,2,0,0,1,98.112,105.9Z"
						transform="translate(-74.487 -72.442)"
						fill="#e1fb4a"
					/>
					<path
						d="M97.578,94.541a2,2,0,0,1-1.022,1.413l8.719,6.16a2,2,0,0,1,1-1.424Z"
						transform="translate(-71.26 -78.936)"
						fill="#e1fb4a"
					/>
					<path
						d="M90.153,104.806l9.1-8.838a2.006,2.006,0,0,1-1.2-1.267l-9.12,8.856A2,2,0,0,1,90.153,104.806Z"
						transform="translate(-75.614 -78.844)"
						fill="#e1fb4a"
					/>
					<path
						d="M89.028,102.259l-4.314,7.7a1.993,1.993,0,0,1,1.485.915l4.35-7.76A1.993,1.993,0,0,1,89.028,102.259Z"
						transform="translate(-78.029 -74.524)"
						fill="#e1fb4a"
					/>
					<path
						d="M113.788,98.485l-5,8.372a2,2,0,0,1,1.411,1.036l5.081-8.515A2,2,0,0,1,113.788,98.485Z"
						transform="translate(-64.267 -76.681)"
						fill="#e1fb4a"
					/>
					<path
						d="M96.049,96.894a2,2,0,0,1,1.432.6l1.2-10.962a2.01,2.01,0,0,1-.633.1,1.98,1.98,0,0,1-1.1-.328l-1.163,10.6A1.99,1.99,0,0,1,96.049,96.894Z"
						transform="translate(-71.698 -83.639)"
						fill="#e1fb4a"
					/>
					<path
						d="M111.773,88.374c-.058,0-.115,0-.171-.009l4.108,13.418-9.135-6.828a2,2,0,0,1-1.045,1.4l9.83,7.349a1.99,1.99,0,0,1,2.271-1.6L113.6,88.946l9.569,8.068a2.005,2.005,0,0,1,1.124-1.334l-10.561-8.9A2,2,0,0,1,111.773,88.374Z"
						transform="translate(-66.132 -83.374)"
						fill="#e1fb4a"
					/>
					<path
						d="M120.557,110.349,114.61,98.558a2,2,0,0,1-1.556.786l6.035,11.967A2,2,0,0,1,120.557,110.349Z"
						transform="translate(-61.831 -76.64)"
						fill="#e1fb4a"
					/>
					<path
						d="M105.008,99.992a2,2,0,0,1-1.238,1.226l5.757,5.827A2,2,0,0,1,110.941,106Z"
						transform="translate(-67.137 -75.82)"
						fill="#e1fb4a"
					/>
					<path
						d="M122.231,94.461a2,2,0,0,1-1.463.948l3.648,5.637-9.673-1.6,4.966-4.193a2.007,2.007,0,0,1-1.124-1.333l-5.034,4.249a2,2,0,0,1,.82,2.981l9.929,1.644a1.979,1.979,0,0,1-.083-.566,2,2,0,0,1,1.755-1.985Z"
						transform="translate(-61.547 -79.291)"
						fill="#e1fb4a"
					/>
					<path
						d="M123.645,100.732a1.992,1.992,0,0,1-1.512-.695l-4.08,9.456a2,2,0,0,1,1.532.847l4.147-9.613C123.7,100.728,123.674,100.732,123.645,100.732Z"
						transform="translate(-58.974 -75.794)"
						fill="#e1fb4a"
					/>
					<path
						d="M86.737,110.665l-4.73-9.649a1.988,1.988,0,0,1-1.546.806l4.792,9.773A2,2,0,0,1,86.737,110.665Z"
						transform="translate(-80.46 -75.235)"
						fill="#e1fb4a"
					/>
					<path
						d="M90.89,110.518a1.985,1.985,0,0,1,.2-1.73l-5.082-.6a1.979,1.979,0,0,1-.478,1.7Z"
						transform="translate(-77.56 -71.134)"
						fill="#e1fb4a"
					/>
					<path
						d="M101.688,113.514,94.132,117.7a1.994,1.994,0,0,1,.924,1.48l7.682-4.257A2,2,0,0,1,101.688,113.514Z"
						transform="translate(-72.646 -68.092)"
						fill="#e1fb4a"
					/>
					<path
						d="M96.9,94.575a2,2,0,0,1,.519,1.342,1.928,1.928,0,0,1-.03.332L108.054,93.2a1.992,1.992,0,0,1-.512-1.333,2.062,2.062,0,0,1,.031-.344Z"
						transform="translate(-71.065 -80.662)"
						fill="#e1fb4a"
					/>
					<path
						d="M104.971,99.959l-7.762,7.426a2.005,2.005,0,0,1,1.205,1.259l7.762-7.426A2,2,0,0,1,104.971,99.959Z"
						transform="translate(-70.887 -75.839)"
						fill="#e1fb4a"
					/>
					<path
						d="M125.834,108.639a1.982,1.982,0,0,1,.212-.893l-11.024-2.727c0,.022,0,.046,0,.069a2,2,0,0,1-.732,1.546l10.393,2.571-5.354,1.763-7.13,5.108,1.455-9.091a1.993,1.993,0,0,1-2.43-1.023l-10.471,10.25a1.993,1.993,0,0,1,.8,2.862l7.65-.162a1.979,1.979,0,0,1-.038-1.743l-7.048.149,9.629-9.426-1.309,8.186a1.994,1.994,0,0,1,2.518,1.6l7.176-5.139,6.663-2.2A2,2,0,0,1,125.834,108.639Z"
						transform="translate(-68.859 -72.946)"
						fill="#e1fb4a"
					/>
					<path
						d="M95.352,119.338l-3.681-9.225a1.992,1.992,0,0,1-1.476.652c-.049,0-.1,0-.143-.006l3.743,9.384A1.994,1.994,0,0,1,95.352,119.338Z"
						transform="translate(-74.977 -70.036)"
						fill="#e1fb4a"
					/>
					<path
						d="M106.594,93.641l4.877-5.485a2,2,0,0,1-1.366-1.088l-4.814,5.416A2,2,0,0,1,106.594,93.641Z"
						transform="translate(-66.268 -83.206)"
						fill="#e1fb4a"
					/>
					<path
						d="M99.869,106.728l-9.8-5.155a1.993,1.993,0,0,1-.811,1.542l9.8,5.155A1.993,1.993,0,0,1,99.869,106.728Z"
						transform="translate(-75.432 -74.917)"
						fill="#e1fb4a"
					/>
					<path
						d="M82.021,103.932,88.48,93.167c1.749,6.971,2.687,10.567,3.23,12.45a1.97,1.97,0,0,1,1.661-.519c-.757-2.741-2.211-8.425-3.294-12.731a2,2,0,0,1-2.827-.537L80.5,103.081A2,2,0,0,1,82.021,103.932Z"
						transform="translate(-80.437 -80.485)"
						fill="#e1fb4a"
					/>
					<path
						d="M86.607,92.972a2,2,0,0,1,.289,2.981l12.41,3.819A1.631,1.631,0,0,1,99.3,99.6a1.993,1.993,0,0,1,.632-1.457L87.685,94.372l14.2-7.616a1.962,1.962,0,0,1-.569-1.672Z"
						transform="translate(-76.947 -84.341)"
						fill="#e1fb4a"
					/>
					<path
						d="M98.587,84.611a1.995,1.995,0,0,1-.706,2.714l9.17,7.966a2,2,0,0,1,1.143-1.317l-8.623-7.49,14.677,1.762a1.984,1.984,0,0,1,.222-1.729Z"
						transform="translate(-70.503 -84.611)"
						fill="#e1fb4a"
					/>
				</g>
				<path
					d="M101.009,162.025v-8.68a39.061,39.061,0,0,0,39.062-39.062V77.391h8.68v36.892a47.743,47.743,0,0,1-47.742,47.742Z"
					transform="translate(17.363 12.628)"
					fill="#fff"
				/>
				<path
					d="M112.581,165.549A38.735,38.735,0,0,1,91.45,155.214q-.354-.337-.7-.682c-.231-.231-.457-.464-.682-.7A38.748,38.748,0,0,1,79.733,132.7a39.752,39.752,0,0,1-.423-5.791,39.062,39.062,0,1,1,78.124,0V99.44a48.346,48.346,0,0,0-5.3-6.289,47.742,47.742,0,0,0-67.518,0,48.334,48.334,0,0,0-5.3,6.289V55.3H70.629v71.614a47.743,47.743,0,0,0,47.743,47.742v-8.68A39.535,39.535,0,0,1,112.581,165.549Z"
					transform="translate(0 0)"
					fill="#5fa0ad"
				/>
			</g>
		</svg>
	);
}
