import React from 'react';

import { Link } from 'gatsby';
import classNames from 'classnames';

import Spinner from './spinner.jsx';

export default function Button(props) {
	let { as, to, pending, children, ariaLabel, ...other } = props;

	if (as === 'link') {
		if (to == undefined) {
			console.warn('Please provide a to property when using a Button as Link'); // eslint-disable-line no-console
		}

		return (
			<div className="relative inline-flex overflow-hidden rounded-full group">
				<div className="absolute w-0 h-full transition-all duration-300 ease-in transform bg-primary group-hover:w-full"></div>
				<Link
					to={to ?? '#'}
					aria-label={ariaLabel}
					className="z-10 block px-6 py-2 transition-colors duration-300 transform border-2 rounded-full border-primary text-primary group-hover:text-secondary focus:outline-none focus:bg-primary focus:text-secondary"
				>
					{children}
				</Link>
			</div>
		);
	}

	let childrenClassName = classNames({ visible: pending === false }, { invisible: pending });
	let renderChildren = <span className={childrenClassName}>{children}</span>;

	let renderSpinner;
	if (pending) {
		renderSpinner = <Spinner className="absolute w-5 h-5 text-primary" />;
	}

	return (
		<div className="relative inline-flex overflow-hidden rounded-full group">
			<div className="absolute w-0 h-full transition-all duration-300 ease-in transform bg-primary group-hover:w-full"></div>
			<button
				{...other}
				aria-label={ariaLabel}
				className="z-10 inline-flex items-center justify-center flex-shrink-0 px-6 py-2 overflow-hidden transition-colors duration-300 transform border-2 rounded-full border-primary text-primary group-hover:text-secondary focus:outline-none focus:bg-primary focus:text-secondary"
			>
				{renderSpinner}
				{renderChildren}
			</button>
		</div>
	);
}
